'use strict';

/*******************************************************************************************/
class UxrPopupContent20Network extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	renderDuoLangLabel(deu, eng) {
		return (
			<span>
				<span>{deu}</span>
				<span dangerouslySetInnerHTML={{__html:"&#x29F8;"}} />
				<span style={{color:"darkgray"}}>{eng}</span>
			</span>
		);
	}
	
	/*---------------------------------------------------------------------*/
	renderInfoSection(test, title, content, html) {
		var o = this;
		
		title = title.split("/");
		title = (
			<h3>
				{o.renderDuoLangLabel(title[0], title[1])}
			</h3>
		);
		
		if (test) {
			switch (typeof(test)) {
				case "string":
					if (test.length===0) {
						return null;
					}
					break;
				case "object":
					if (test instanceof Array) {
						if (test.length===0) {
							return null;
						}
					} else {
						if (true
							&& (!('de' in test) || test.de.length===0)
							&& (!('en' in test) || test.en.length===0))
						{
							return null;
						}
					}
				
					break;
				default:
					console.error(test);
					return null;
			}
		} else {
			return null;
		}
			
		let contentDe = null;
		let contentEn = null;
		
		if (typeof(content) === "string" || content instanceof Array) {
			contentDe = content;
		} else {
			contentDe = content.de;
			if (content.en && content.en.length > 0) {
				contentEn = content.en;
			}
		}
		
		if (html) {
			contentDe = (<div dangerouslySetInnerHTML={{__html:contentDe}} />)
			if (contentEn) {
				contentEn = (
					<div>
						<div style={{paddingTop: '0.6em'}} />
						<div className="languageMarkerLabel">English</div>
						<div dangerouslySetInnerHTML={{__html:contentEn}} />
					</div>
				);
			}
		} else {
			contentDe = (<div>{contentDe}</div>);
			if (contentEn) {
				contentEn = (
					<div>
						<div style={{paddingTop: '0.6em'}} />
						<div className="languageMarkerLabel">English</div>
						<div>{contentEn}</div>
					</div>
				);
			}
		}
		
		return (
			<div>
				{title}
				{contentDe}
				{contentEn}
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let o = this;
		
		let p = GLOBALS.popupsData[this.props.popupId];
		let r = {};
		
		r.controlsTab = (
			<div className="popupContainer_tab" data-contenttype=".content_network">
				<div className="fa fa-info-circle"></div>
			</div>
		);
		
		let contact = "";
		_.forEach(p.field_kontaktangaben_public_tele, function(tel) {
			contact += 'Tel: <a href="tel:'+tel+'">'+tel+'</a><br />';
		});
		_.forEach(p.field_kontaktangaben_public_emai, function(email) {
			contact += 'Email: <a href="mailto:'+email+'">'+email+'</a><br />';
		});
		
		if (p.field_kontaktangaben_public_link.length > 0) {
			contact += '<p>';
		}
		_.forEach(p.field_kontaktangaben_public_link, function(link) {
			let label = link.label || link.url;
			
			contact += '<a href="'+link.url+'">'+label+'</a><br />';
		});
		if (p.field_kontaktangaben_public_link.length > 0) {
			contact += '</p>';
		}
		
		if (p.field_kontaktangaben_public_more) {
			contact += p.field_kontaktangaben_public_more;
		}
		
		

		let shareUrl = encodeURIComponent("http://remembering-leipzig.de/interaktiv#!entry=" + p.id);
		let shareButtons = (
			<div className="socials">
				<a className="socials-facebook" target="_blank" href={'http://www.facebook.com/sharer/sharer.php?u='+ shareUrl}><div className="fa fa-facebook-square"></div></a>
				<a className="socials-twitter" target="_blank" href={"https://twitter.com/intent/tweet?url="+ shareUrl}><div className="fa fa-twitter-square"></div></a>
			</div>
		);
		
		r.content = (
			<div className="contentTab content_network">
				<div className="flowRight writeCaps">netzwerk</div>
				<div className="flowClear"></div>
				<div className="scrollable">
					<div dangerouslySetInnerHTML={{__html:p.body}} />
					{this.renderInfoSection(p.field_nennungen_im_bezug_zur_jud, 'Nennungen im Bezug zur Jüdischen Geschichte Leipzigs/Specialisation', p.field_nennungen_im_bezug_zur_jud, 'html')}
					{this.renderInfoSection(contact, 'Kontakt/Contact', contact, 'html')}
				</div>
			</div>
		);
		
		let props = {};
		props['data-id'] = this.props.popupId;
		props['ref'] = this.onJQueryMount;
		
		return (
			<div className="popupContainer" {...props}>
				<div className="popupContainer_controls">
					{r.controlsInfo}
					<div className="popupContainer_iconClose fa fa-close"></div>
					<div className="popupContainer_iconNext fa fa-angle-right writeBold"></div>
					<div className="popupContainer_iconPrev fa fa-angle-left writeBold"></div>
					<div className="flowClear"></div>
				</div>
				
				<div className="popupContainer_content">
					<h2 className="title">{p.title}</h2>
					<div className="content">
						{r.content}
						{shareButtons}
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
	}
	
	/*---------------------------------------------------------------------*/
	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		let p = GLOBALS.popupsData[this.props.popupId];
		p.fulltext = this.jQ.text();
		p.jQ = this.jQ;
	}
	
} //class
